// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-company-about-js": () => import("./../../../src/pages/company/about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-careers-js": () => import("./../../../src/pages/company/careers.js" /* webpackChunkName: "component---src-pages-company-careers-js" */),
  "component---src-pages-company-leadership-js": () => import("./../../../src/pages/company/leadership.js" /* webpackChunkName: "component---src-pages-company-leadership-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-racgp-cpd-course-js": () => import("./../../../src/pages/racgp-cpd-course.js" /* webpackChunkName: "component---src-pages-racgp-cpd-course-js" */),
  "component---src-pages-racgp-evaluation-js": () => import("./../../../src/pages/racgp-evaluation.js" /* webpackChunkName: "component---src-pages-racgp-evaluation-js" */),
  "component---src-pages-solutions-betterconsult-betterconsult-notes-js": () => import("./../../../src/pages/solutions/betterconsult/betterconsult-notes.js" /* webpackChunkName: "component---src-pages-solutions-betterconsult-betterconsult-notes-js" */),
  "component---src-templates-audience-page-js": () => import("./../../../src/templates/AudiencePage.js" /* webpackChunkName: "component---src-templates-audience-page-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/BlogCategory.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-careers-page-js": () => import("./../../../src/templates/CareersPage.js" /* webpackChunkName: "component---src-templates-careers-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-solution-page-js": () => import("./../../../src/templates/SolutionPage.js" /* webpackChunkName: "component---src-templates-solution-page-js" */),
  "component---src-templates-targeted-page-js": () => import("./../../../src/templates/TargetedPage.js" /* webpackChunkName: "component---src-templates-targeted-page-js" */)
}

